import {createContext, useEffect, useState} from 'react'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import PageConfig from '@component/layouts/PageConfig/PageConfig'
import {useRouter} from 'next/router'
import {apiCheckReferral} from '@api/auths/register/checkReferral'
import {useLoginModalStore} from '@store/LoginModalStore'
import useScreenLog from '@hook/useScreenLog'
import Head from 'next/head'
import LoginModalRouter from '@feature/common/components/LoginModal/LoginModalRouter'

export const LoginModalContext = createContext({
    appliedReferral: '',
    isModal: true,
})

declare global {
    interface Window {
        Kakao: any
        FB: any
    }
}

const LoginPage = ({code}) => {
    const router = useRouter()
    const {} = useScreenLog({pageName: 'login'})
    const [isModal] = useState(false)
    const [appliedReferral, setAppliedReferral] = useState(code)
    const {setRedirectUrl} = useLoginModalStore(state => ({
        setRedirectUrl: state.setRedirectUrl,
    }))

    useEffect(() => {
        setRedirectUrl('/leaderboard')
    }, [setRedirectUrl])

    useEffect(() => {
        const validateReferral = async (referralCode?: string) => {
            const {data, status} = await apiCheckReferral(referralCode)
            if (data) {
                if (data?.is_valid) {
                    setAppliedReferral(router?.query?.referral?.toString())
                } else {
                    setAppliedReferral('')
                    await router?.replace(router?.pathname, undefined, {shallow: true})
                }
            }
        }

        if (router?.query?.referral) {
            validateReferral(router?.query?.referral?.toString())
        }
    }, [router?.query?.referral])

    return (
        <PageConfig hideLoginButtons={true}>
            <Head>
                <link rel="preload" as="image" href="/images/img_login_modal_side_1.png" />
                <link rel="preload" as="image" href="/images/login/img_referral_login.webp" />
            </Head>
            <div className={'flex justify-center items-center h-full mx-auto bg-blue_tint02 dark:bg-dark_blue_tint02'}>
                <div className={'max-w-[800px] mt-[55px] mb-[90px] shadow-hover'}>
                    <LoginModalContext.Provider
                        value={{
                            appliedReferral,
                            isModal,
                        }}>
                        <LoginModalRouter closeModal={() => null} />
                    </LoginModalContext.Provider>
                </div>
            </div>
        </PageConfig>
    )
}

export const getServerSideProps = async ctx => {
    const cookies = ctx.req.cookies
    const locale = cookies.language || ctx.locale || 'en'

    return {
        props: {
            code: ctx.query?.referral || '',
            ...(await serverSideTranslations(locale, ['common'])),
        },
    }
}

export default LoginPage
